import React, { useState } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBIcon,
  MDBNavbarNav,
} from "mdb-react-ui-kit";

import logo from "../pictures/logo_light.png";
import playimg from "../pictures/dowload_btn.png";

import { Link } from "react-router-dom";

export default function Landnavbar(props) {
  const handleDownloadClick = () => {
    const apkUrl = "https://api.foudhan.com/download/foudhandataapp.apk";
    const link = document.createElement("a");
    link.href = apkUrl;
    link.download = "foudhandataapp.apk";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [openNavNoTogglerSecond, setOpenNavNoTogglerSecond] = useState(false);

  return (
    <div className="land-bg">
      <MDBNavbar className="land-bg" expand="lg" light>
        <MDBContainer fluid>
          <MDBNavbarBrand>
            <Link className="logo-text" to={"/"}>
              <img
                src={logo}
                alt="foudhan logo"
                style={{ width: "50px", height: "50px" }}
              />
              Foudhan
            </Link>
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setOpenNavNoTogglerSecond(!openNavNoTogglerSecond)}
          >
            <MDBIcon icon="bars" fas style={{ color: "white" }} />
          </MDBNavbarToggler>

          <MDBCollapse navbar open={openNavNoTogglerSecond}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <Link className="d_link" to="/mob-register">
                    Registration
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <Link className="d_link" to="/mob-login">
                    Login
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  onClick={() => {
                    props.navScrol("ft");
                  }}
                >
                  <Link className="d_link" to={""}>
                    Features
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  onClick={() => {
                    props.navScrol("pc");
                  }}
                >
                  <Link className="d_link" to={""}>
                    Pricing
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink
                  onClick={() => {
                    props.navScrol("sp");
                  }}
                >
                  <Link className="d_link" to={""}>
                    Services
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  onClick={() => {
                    props.navScrol("sp");
                  }}
                >
                  <Link className="d_link" to={""}>
                    Support
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <div>
                    <img
                      onClick={() => {
                        handleDownloadClick();
                      }}
                      src={playimg}
                      className="w-100 apk-download-btn"
                      style={{ cursor: "pointer", height: "40px" }}
                    />
                  </div>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
}
