import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Landnavbar from "./landnavbar";
import "../css/landhome.css";
import Footer from "./footer";
import Features from "./features";
import Pricing from "./pricing";
import Support from "./support";
// import { motion } from "framer-motion/dist/framer-motion";

export default function Landhome() {
  const navigate = useNavigate();
  const [scrollFt, setScrollFt] = useState(false);
  const [scrollPc, setScrollPc] = useState(false);
  const [scrollSp, setScrollSp] = useState(false);
  const [scrollAh, setScrollAh] = useState(false);

  const handleScrollFt = (ctr) => {
    if (ctr === "ft") {
      setScrollFt(true);
    } else if (ctr === "pc") {
      setScrollPc(true);
    } else if (ctr === "ah") {
      setScrollAh(true);
    } else {
      setScrollSp(true);
    }
  };

  useEffect(() => {
    setScrollFt(false);
    setScrollPc(false);
    setScrollSp(false);
    setScrollAh(false);
  });

  return (
    <div>
      <Landnavbar navScrol={handleScrollFt} />
      <div className="home-card-bg">
        <div className="home-content ">
          <div
            className="home-font"
            style={{ textTransform: "uppercase", width: "60%" }}
          >
            <h1>
              <div class="roller" style={{ textAlign: "left" }}>
                <span id="rolltext">
                  <div style={{ padding: "10px" }}>WE STAND</div>
                  <div style={{ padding: "10px" }}>TO MAKE YOUR LIFE</div>
                  <span id="spare-time">EASIER</span>
                </span>
              </div>
            </h1>
          </div>

          <div className="home-font ft" style={{ fontSize: "x-large" }}>
            <p>
              Our reliable software solution enables you to conveniently engage
              in various services, including data reselling, airtime top-up,
              <br /> cable subscriptions, utility payments, and school scratch
              cards.
            </p>
          </div>

          <div style={{ position: "relative" }}>
            <button
              onClick={() => {
                navigate("/mob-register");
              }}
            >
              Get Started...
            </button>
          </div>
        </div>
      </div>

      <Features scrollMe={scrollFt} />
      <Pricing scrollMe={scrollPc} />
      {/* <AcademyHome scrollMe={scrollAh} /> */}
      <Support scrollMe={scrollSp} />
      <Footer />
    </div>
  );
}
