import siteLogoImg from "../pictures/logo.png";

export const siteName = "FOUDHANDATA"; //this will display on sidebar
export const smsName = "foudhandata"; // for bulk sms sending on behalf of the bussiness
export const appMainColor = "#6b0808"; // app's primary color
export const siteLogo = siteLogoImg; // site logo
export const baseApiUrl = "https://api.foudhan.com/"; // for api call
export const contactNumber = "09016609192";
export const contactEmail = "support@foudhan.com";
export const contactAddress = "Sokoro road, Funtua";
export const appname = "foudhandata.apk";
export const appApkUrl = baseApiUrl + "download/" + appname;
export const monnifyApiParams =
  "MK_TEST_LRPY0VY5Q0:WJ7FGVFZ2P5PWD4LD2ZUVWG8FU137SLQ";
export const whatsappChat = "https://wa.me/+2349016609192";
export const whatsappGroup = "https://chat.whatsapp.com/K03PZaulr4nCA8FhmvbemG";
